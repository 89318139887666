@import '~antd/dist/antd.css';
@import '~handsontable-pro/dist/handsontable.full.css';

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.flex {
  display: flex;
}

::-webkit-scrollbar {
  width: 7px !important;
  height: 7px !important;
  background-color: #fff;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
  border: 1px solid #fff;
  background-color: #fff;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #1890ff80;
  border: 1px solid #fff;
}
::-webkit-scrollbar-thumb:hover {
  background: #1890ff;
}
::-webkit-scrollbar-thumb:active {
  background-color: #1890ff;
}